html {
  box-sizing: border-box;
  min-width: 400px;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000000;
}

#container {
  height: 100vh;
}

#granim-canvas {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1; 
}

#model {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}

model-viewer {
  background-color: transparent;
  width: 100%;
  height: 100%;
}